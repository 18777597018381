// src/components/layout.js
import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import { Link, PageProps } from 'gatsby'

const shortcodes = { Link }

export default ({ children }: PageProps) => (
  <MDXProvider components={shortcodes}>{children}</MDXProvider>
)
